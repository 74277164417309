@font-face {
    font-family:gilroy_regular;
    src: url('../../public/Gilroy-Regular.ttf');
}

@font-face {
    font-family:gilroy_bold;
    src: url('../../public/Gilroy-Bold.ttf');
}

@font-face {
    font-family:gilroy_black;
    src: url('../../public/Gilroy-Black.ttf');
}

@font-face {
    font-family:caprasimo;
    src: url('../../public/Caprasimo-Regular.ttf');
}

@font-face {
    font-family:russo;
    src: url('../../public/RussoOne-Regular.ttf');
}

@font-face {
    font-family:lilita;
    src: url('../../public/LilitaOne-Regular.ttf');
}

@font-face {
    font-family:gilroy_black;
    src: url('../../public/Gilroy-Black.ttf');
}

@font-face {
    font-family:outer_text;
    src: url('../../public/Goffik-O.ttf');
}

.font_thick{
    font-family:gilroy_regular !important;
}

.font_bold{
    font-family:gilroy_bold !important;
}

.font_expand{
    font-family:gilroy_bold !important;
}

.font_thicker{
    font-family:gilroy_black !important;
}

.font_thicksss{
    font-family:lilita !important;
}

.font_section1{
    font-family:gilroy_regular !important;
}

.font_outer_text{
    font-family:outer_text !important;
}